import React, { useState, useEffect  } from 'react';

const ImageGallery = () => {

    const [images, setImages] = useState([]);
    const [displayedImages, setDisplayedImages] = useState([]);
    const [displayedCount, setDisplayedCount] = useState(5);
    const [selectedImage, setSelectedImage] = useState(null); 
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        
        const fetchedImages = [

        { id: 1,  lowResSrc: require('../img/Eco.webp'),    highResSrc: require('../img/Eco.webp'), alt: 'Image 1' },
        { id: 2,  lowResSrc: require('../img/Eco2.webp'),   highResSrc: require('../img/Eco2.webp'), alt: 'Image 2' },
        { id: 3,  lowResSrc: require('../img/Eco3.webp'),   highResSrc: require('../img/Eco3.webp'), alt: 'Image 3' },
        { id: 4,  lowResSrc: require('../img/Eco4.webp'),   highResSrc: require('../img/Eco4.webp'), alt: 'Image 4' },
        { id: 5,  lowResSrc: require('../img/Eco5.webp'),   highResSrc: require('../img/Eco5.webp'), alt: 'Image 5' },
        { id: 6,  lowResSrc: require('../img/Eco6.webp'),   highResSrc: require('../img/Eco6.webp'), alt: 'Image 6' },
        { id: 7,  lowResSrc: require('../img/Eco7.webp'),   highResSrc: require('../img/Eco7.webp'), alt: 'Image 7' },
        { id: 8,  lowResSrc: require('../img/Eco8.webp'),   highResSrc: require('../img/Eco8.webp'), alt: 'Image 8' },
        { id: 9,  lowResSrc: require('../img/Eco9.webp'),   highResSrc: require('../img/Eco9.webp'), alt: 'Image 9' },
        { id: 10, lowResSrc: require('../img/Eco10.webp'),  highResSrc: require('../img/Eco10.webp'), alt: 'Image 10' },
        { id: 11, lowResSrc: require('../img/Eco11.webp'),  highResSrc: require('../img/Eco11.webp'), alt: 'Image 11' },
        { id: 12, lowResSrc: require('../img/Eco12.webp'),  highResSrc: require('../img/Eco12.webp'), alt: 'Image 12' },
        { id: 13, lowResSrc: require('../img/Eco13.webp'),  highResSrc: require('../img/Eco13.webp'), alt: 'Image 13' },
        { id: 14, lowResSrc: require('../img/Eco14.webp'),  highResSrc: require('../img/Eco14.webp'), alt: 'Image 14' },
        { id: 15, lowResSrc: require('../img/Eco15.webp'),  highResSrc: require('../img/Eco15.webp'), alt: 'Image 15' },


    ]

    setImages(fetchedImages);
}, []);

useEffect(() => {
    setDisplayedImages(images.slice(0, displayedCount));
}, [images, displayedCount]);

const handleImageLoad = (index) => {
    setImages(prevImages => {
        const updatedImages = [...prevImages];
        updatedImages[index].loaded = true; // Mark the image as loaded
        return updatedImages;
    });
};

const loadMoreImages = () => {
    setDisplayedCount(prevCount => prevCount + 5); // Increment displayed count by 5
};

const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
    console.log(openModal)
};

const closeModal = () => {
    setIsModalOpen(false);
};

useEffect(() => {
    const loadInitialImages = async () => {
        const initialImages = images.slice(0, displayedCount);
        await Promise.all(initialImages.map(async (image, index) => {
            const img = new Image();
            img.src = image.lowResSrc;
            await new Promise(resolve => {
                img.onload = () => resolve();
            });
            handleImageLoad(index);
        }));
    };
    
    loadInitialImages();
}, [images, displayedCount]);

return (
    <div className='image-gallery'>
        <div>
        {displayedImages.map((image, index) => (
            <img
                key={image.id}
                src={image.lowResSrc}
                alt={image.alt}
                className={`gallery-image ${image.loaded ? 'loaded' : ''}`}
                onLoad={() => handleImageLoad(index)}
                onClick={() => openModal(image)}
            />
        ))}
        </div>
        <div>
        {displayedImages.length < images.length &&
            <button onClick={loadMoreImages} className='gallery-button'>Load More</button>
        }
        </div>
            {isModalOpen && selectedImage && (
                <div className={`modal ${isModalOpen ? 'isopen' : ''}`}>
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <img src={selectedImage.highResSrc} alt={selectedImage.alt} className={`image-big ${isModalOpen ? 'isopen' : ''}`}/>
                    </div>
                </div>
            )}
    </div>
);
};

export default ImageGallery;