import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Cleancommitment from './components/pages/clean-commitment';
import Testimony from './components/pages/testimonials';
import Contact from './components/pages/contact';
import Lehms from './components/pages/laundry_edu_how_much_soap';
import Lecs from './components/pages/laundry_edu_charlies';
import Lesf from './components/pages/Laundry_edu_sanitize';
import Gallery from './components/pages/gallery';
import Career from './components/pages/careers';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/clean-commitment" element={<Cleancommitment />} />
          <Route path="/testimonials" element={<Testimony />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/laundry_edu/how-much-soap" element={<Lehms />} />
          <Route path="/laundry_edu/charlies-soap" element={<Lecs />} />
          <Route path="/laundry_edu/sanitize-feature" element={<Lesf />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/careers" element={<Career />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
