import React, {useEffect} from 'react'
import Header from '../functions/header'
import Footer from '../functions/footer'
import seats from '../img/Eco9.webp'
import soap from '../img/laundry_liquid.png'
import wool from '../img/wool_dryer.png'
import dryer from '../img/washing_machine.png'
import lowerview from '../img/Eco5.webp' 


const Cleancommitment = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='clean-commitment-container'>
      <div>
        <Header />
      </div>
      <div className='clean-commitment-subheader'>
        <h1>
          Corvallis Eco Laundry Commitment
        </h1>
      </div>
      <div className='clean-commitment-contents'>
        <img src={seats} alt="" className='clean-commitment-img1'/>
        <h2>
          Your Time 
        </h2>
        <p>
          Sit back, relax and enjoy the most energy 
          efficient professional laundry experience 
          in Corvallis.  That is our Corvallis Eco Laundry commitment to you! 
          With speeds of <b>450-G force</b>, our <b><em>professional</em></b>  
          &nbsp;washers extract up to <b>70% more water</b> than 
          traditional commercial laundry equipment.  
          Depending on the size of the washer, <em>up to 
          40lbs more water</em> is removed from your clothes 
          in our washers!
        </p>
        <p>
          That means, paired with our professional dryers 
          that include Reversing, Moisture Sensing AND Air 
          Flow Technology; you receive the <b>highest quality 
          wash and dry in 45 minutes!</b>
        </p>
        <p>
          Quarters will always be for laundry; but at 
          Corvallis Eco Laundry you can pay however you dare!  Credit/debit 
          card, Fascard app, Loyalty card 
          (earn points toward free wash) and always the 
          quarter.   We have a dual hopper changer with 
          a dime and nickel to quarter acceptor too!  Ask a 
          CEL Crew team member about how to budget your laundry 
          for the month with our payment app or loyalty card.  We 
          make it easy to be clean!
        </p>
        <p>
          45 minutes washed <b> AND </b> dried is pretty darned fast, if you 
          ask us.  In case you have some work to do or maybe some 
          shopping or browsing, don’t despair- we offer complimentary 
          WiFi  for each and every customer.
        </p>
        <img src={soap} alt="" className='clean-commitment-images'/>
        <h2>
          Our Environment
        </h2>
        <p>
          Corvallis Eco Laundry is committed to the environment. Our wastewater 
          treatment facilities cannot remove carcinogens such as 
          Dioxane; a by-product created from the vast majority of laundry 
          soap.  Our washers, engineered for hospitals, are not compatible 
          with residential high sudsing soaps.
        </p>
        <p>
          As a sign of our dedication to the environment, and to your clothing 
          investment and health, <b>Complimentary Charlie’s detergent is our 
          commitment with every wash. You read that right, we pay for the soap 
          every time!  No need to go to the store to buy, lug home and drag back 
          to the mat.  We make it easy.</b>
        </p>
        <p>
          Charlie’s soap has been third-party tested to be of the highest quality 
          and safest laundry detergent on the market with the most superior results. 
          <b>Charlies is Biodegradable, hypoallergenic and petrochemical and Dioxane free. 
          Read more about this product at <a href="https://www.charliesoap.com/#/" className='www-charlies'> <em>www.charliesoap.com</em></a></b>.
        </p>
        <p>
          If you try Charlies and find it isn’t a perfect fit; alternative safe laundry 
          detergent options are available from any Corvallis Eco Laundry Crew member, along with a list of 
          third-party tested safe and effective options you can purchase at most grocery stores
        </p>
        <p>
          We will always help you find a compatible soap that meets your expectations that is 
          safe for both you and the environment. That is our Corvallis Eco Laundry Commitment to you.
        </p>
        <img src={wool} alt="" className='clean-commitment-images'/>
        <h2>
          Your Clothing
        </h2>
        <p>
          Some of your linen and clothing investments can be considerable.  We are committed 
          to taking special care of your specialty items.  We have Specialty Cycles installed 
          in our washers that include; Delicate Cold/Warm, Wool, Active Wear, Down, Sleeping 
          bags, Comforters and our Special Heavy Soil Wash that now includes a soak cycle.
        </p>
        <p>
          Our commitment includes the special care of your clothing/linen investment.  
          Occasionally items are brought in that are not compatible with our equipment. 
          To protect both investments, there may be items we respectfully must decline 
          being washed at Corvallis Eco Laundry.
        </p>
        <p>
          <b>Enjoy complimentary use of our wool dryer balls with each dry</b>.  These naturally 
          reduce static, soften clothes and speed up dry time. We offer a variety of 
          essential oil scents added to gently scent your clothing without any nasty 
          residue or petrochemicals.
        </p>
        <img src={dryer} alt="" className='clean-commitment-images'/>
        <h2>
          Your Health
        </h2>
        <p>
          Corvallis Eco Laundry is committed to…. CLEAN! Our Electrolux Professional 450 G-force washers 
          are the only washers in the world to offer a sanitize feature, which we offer 
          complimentary to you. The Corvallis Eco Laundry commitment includes sanitizing door handles, 
          folding tables and laundry carts several times daily. Our restrooms are checked, 
          restocked and cleaned with each shift. Our Corvallis Eco Laundry Crew is busy cleaning all day,
          every day to ensure our facility will always be the <b>Cleanest in town!</b>
        </p>
        <p>
          A unique and important way we protect your health at Corvallis Eco Laundry is with our <b>Wash Here 
          to Dry Here</b> policy.  By ensuring that all items are washed in our hospital grade 
          washers prior to entering the dryer, we can guarantee the highest quality and safest
          wash in Benton County. Nothing is more frustrating than having to rewash your clothes 
          because a customer before you was able to dry soiled clothing.  You don’t have to 
          worry about this at Corvallis Eco Laundry.  We work diligently to ensure all items that enter Corvallis Eco Laundry 
          have been washed at Corvallis Eco Laundry immediately prior to drying.  This is another way we protect 
          your clothing and your health. We are proud to implement this safe and sound policy and 
          we are happy this policy is consistently a customer favorite and thankful for the 
          positive feedback.
        </p>
        <p>
          Our commitment to a clean and comfortable restroom for customer use only adds to our 
          overall superior service commitment.
        </p>
        <h2>
          Your Safety
        </h2>
        <p>
          Our commitment to your safety includes a surveillance system, our outstanding Corvallis Eco Laundry Crew 
          on site during all business hours, patrol services throughout the day and security to 
          lock and alarm the facility at night and in the morning.
        </p>
        <p>
          Special laundry circumstances require special attention.  If you find that you have exceedingly 
          soiled laundry including but not limited to excrement (including baby diapers), petroleum, 
          gasoline, ammonia, pesticides, herbicides or any chemical by-product, anything flammable or 
          exceedingly odiferous, bed bugs or lice; please reach out to us for suggestions about how to 
          properly and safely launder.  Our Corvallis Eco Laundry Commitment to our customers is to ensure they have the 
          highest level of clean each and every time they wash here, and as such, <b>it is our commitment not 
          to process special laundry in our facility as those items would require a special process that we
          do not employ.</b>
        </p>
        <img src={lowerview} alt="" className='clean-commitment-images'/>
        <h2>
          Customer Service
        </h2>
        <p>
          Corvallis Eco Laundry is committed to superior customer service.  If we have exceeded your expectation, we 
          appreciate your review on Google or Yelp.  If we have fallen short in any way, please email us at 
          info@corvallisecolaundry.com.  <b>It is our sincere wish to always deliver more than expected</b>. We appreciate 
          the opportunity to meet your expectations.
        </p>
        <p>
          Welcome to Corvallis Eco Laundry!
        </p>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Cleancommitment