import React, {useEffect} from 'react'
import Header from '../functions/header'
import Footer from '../functions/footer'
import dryer from '../img/washing_machine.png'

const Lesf = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div>
        <Header />
        <div className='lesf-sub-header'>
          <h1>
            Complimentary Sanitizing
          </h1>
        </div>
      </div>
      <div className='lesf-page-container'>
        <img src={dryer} alt="" className='img-fluid'/>
        <p>
          Corvallis Eco Laundry is committed to…. CLEAN! Our Electrolux Professional 450 G-force 
          washers are the only washers in the world to offer a sanitize rinse feature, 
          which we offer complimentary to you.
        </p>
        <p>
          The Corvallis Eco Laundry commitment includes sanitizing door handles, folding tables and 
          laundry carts several times daily. Our restrooms are checked, restocked and 
          cleaned with each shift. Our Corvallis Eco Laundry Crew is busy cleaning all day, every day 
          to ensure our facility will always be the Cleanest in Benton County.
        </p>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Lesf